.react-flow {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.react-flow__container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.react-flow__pane {
  z-index: 1;
}

.react-flow__viewport {
  transform-origin: 0 0;
  z-index: 2;
  pointer-events: none;
}

.react-flow__renderer {
  z-index: 4;
}

.react-flow__selectionpane {
  z-index: 5;
}

.react-flow .react-flow__edges {
  pointer-events: none;
  overflow: visible;
}

.react-flow .react-flow__connectionline {
  z-index: 1001;
}

.react-flow__edge {
  pointer-events: visibleStroke;
}

.react-flow__edge.inactive {
    pointer-events: none;
  }

@-webkit-keyframes dashdraw {
  from {
    stroke-dashoffset: 10;
  }
}

@keyframes dashdraw {
  from {
    stroke-dashoffset: 10;
  }
}

.react-flow__edge-path {
  fill: none;
}

.react-flow__edge-textwrapper {
  pointer-events: all;
}

.react-flow__edge-text {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.react-flow__connection {
  pointer-events: none;
}

.react-flow__connection .animated {
    stroke-dasharray: 5;
    -webkit-animation: dashdraw 0.5s linear infinite;
            animation: dashdraw 0.5s linear infinite;
  }

.react-flow__connection-path {
  fill: none;
}

.react-flow__nodes {
  pointer-events: none;
  transform-origin: 0 0;
}

.react-flow__node {
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: all;
  transform-origin: 0 0;
  box-sizing: border-box;
}

.react-flow__nodesselection {
  z-index: 3;
  transform-origin: left top;
  pointer-events: none;
}

.react-flow__nodesselection-rect {
    position: absolute;
    pointer-events: all;
    cursor: -webkit-grab;
    cursor: grab;
  }

.react-flow__handle {
  position: absolute;
  pointer-events: none;
}

.react-flow__handle.connectable {
    pointer-events: all;
  }

.react-flow__handle-bottom {
  top: auto;
  left: 50%;
  bottom: -4px;
  transform: translate(-50%, 0);
}

.react-flow__handle-top {
  left: 50%;
  top: -4px;
  transform: translate(-50%, 0);
}

.react-flow__handle-left {
  top: 50%;
  left: -4px;
  transform: translate(0, -50%);
}

.react-flow__handle-right {
  right: -4px;
  top: 50%;
  transform: translate(0, -50%);
}

.react-flow__edgeupdater {
  cursor: move;
  pointer-events: all;
}

/* additional components */

.react-flow__controls {
  position: absolute;
  z-index: 5;
  bottom: 20px;
  left: 15px;
}

.react-flow__controls-button {
    width: 24px;
    height: 24px;
    border: none;
  }

.react-flow__controls-button svg {
      width: 100%;
    }

.react-flow__minimap {
  position: absolute;
  z-index: 5;
  bottom: 20px;
  right: 15px;
}

.react-flow__attribution {
  font-size: 10px;
  position: absolute;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.5);
  padding: 2px 3px;
  color: #999;
}

.react-flow__attribution a {
    color: #555;
    text-decoration: none;
  }

.react-flow__attribution.top {
    top: 0;
  }

.react-flow__attribution.bottom {
    bottom: 0;
  }

.react-flow__attribution.left {
    left: 0;
  }

.react-flow__attribution.right {
    right: 0;
  }

.react-flow__attribution.center {
    left: 50%;
    transform: translateX(-50%);
  }
